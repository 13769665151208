import PropTypes from "prop-types";
import React from "react";
import Button from "../UI/button";
import { IoIosMenu } from "react-icons/io";

const HeaderBtn = ({ styles }) => {
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add(styles.active);
  };
  return (
    <div className="header__btn text-end">
      <div className="desktop-button-wrapper d-none d-lg-block" >
      <p style={{color:"red" ,fontSize:"17px",textAlign:"center"}}>בחרו איפה לפתוח את התוכנה</p>

        <div style={{ display: 'flex', justifyContent:"center",alignItems: 'center' }}>
        <Button type="link" url="https://qmessage.co.il" text="במחשב" btnStyle="white" typee={true}  style={{display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    
                      alignItems: 'center',marginLeft:"11px" }}/>
      
        <Button type="link" url="https://play.google.com/store/apps/details?id=com.qmessage.co.il" text="בנייד" btnStyle="white"  typee={true}   style={{display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',marginLeft:"11px" }}/>
                      </div>
      </div>
      <div className={`${styles.mobileButtonWrapper} d-block d-lg-none`}  style={{marginTop:"-10px",marginLeft:"-10px",marginBottom:"-20px"}}>
      <button
      style={{paddingLeft: "274px"}}
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <IoIosMenu />
        </button>
   
<div style={{ display: 'flex',marginRight:"18px" , justifyContent:"center",alignItems: 'center' }}>

<Button type="link" url="https://qmessage.co.il" text="במחשב" typee={true} btnStyle="light"   style={{
  display: 'flex',
  "background": "#E05941 none repeat scroll 0 0",
              flexDirection: 'column',
              justifyContent: 'center',
              width:"60px",
              fontSize:"13px",
              alignItems: 'center',marginLeft:"18px" }}/>

<Button type="link" url="https://play.google.com/store/apps/details?id=com.qmessage.co.il" text="  בנייד " btnStyle="light"   typee={true}   style={{display: 'flex',
              fontSize:"13px",
              "background": "#E05941 none repeat scroll 0 0",
              flexDirection: 'column',
              justifyContent: 'center',
              width:"60px",
              alignItems: 'center',marginLeft:"18px" }}/>
              </div>
              

              <p style={{color:"red" ,fontSize:"12px",textAlign:"center"}}>בחרו איפה לפתוח את התוכנה</p>
      
      </div>

    </div>
  );
};

HeaderBtn.propTypes = {
  styles: PropTypes.object
};

export default HeaderBtn;
