import React from "react";
import styles from "./Navigation.module.scss";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <nav>
      <ul dir="rtl" className={styles.mainMenu}>
        <li className={styles.drop}>
          <Link to={process.env.PUBLIC_URL + "/"}>
            דף הבית 
            {/* <IoIosArrowDown /> */}
          </Link>
          {/* <ul className={styles.dropdownMenu}>
            <li>
              <Link to={process.env.PUBLIC_URL + "/home-one"}>
                Home version One
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/home-two"}>
                Home version Two
              </Link>
            </li>
          </ul> */}
        </li>
        <li className={styles.drop}>
          <Link to={process.env.PUBLIC_URL + "/service"}>
            על השירות 
            {/* <IoIosArrowDown /> */}
          </Link>
          {/* <ul className={styles.dropdownMenu}>
            <li>
              <Link to={process.env.PUBLIC_URL + "/service"}>מה זה 'הודעה מהירה'?</Link>
            </li>
            <li>
              <Link
                to={process.env.PUBLIC_URL + "/service-who"}
              >
               למי זה יכול להתאים?
              </Link>
            </li>
            {/* <li>
              <Link to={process.env.PUBLIC_URL + "/service-details"}>
                Service Details Right Sidebar
              </Link>
            </li> 
          </ul> */}
        </li>
        {/* <li>
          <Link to={process.env.PUBLIC_URL + "/about"}>אודותינו</Link>
        </li> */}
        <li className={styles.drop}>
          <Link to={process.env.PUBLIC_URL + "/blog"}>
           עדכונים 
           {/* <IoIosArrowDown /> */}
          </Link>
          {/* <ul className={styles.dropdownMenu}>
            <li>
              <Link to={process.env.PUBLIC_URL + "/join-Wa"}>
              הצטרפות לקבוצת העדכונים בווצאפ
              </Link>
            </li>
            {/* <li>
              <Link to={process.env.PUBLIC_URL + "/blog"}>
              הצטרפות לקבוצת העדכונים בווצאפ

              </Link>
            </li> */}
            {/* <li>
              <Link to={process.env.PUBLIC_URL + "/blog-post-left-sidebar"}>
                Blog Post Left Sidebar
              </Link>
            </li> */}
            {/* <li>
              <Link to={process.env.PUBLIC_URL + "/blog-post"}>
                Blog Post Right Sidebar
              </Link>
            </li> 
          </ul> */}
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>יצירת קשר</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
