import React from "react";
import { IoIosSearch } from "react-icons/io";
import styles from "./MobileSearch.module.scss";

const MobileSearch = () => {
  return (
    <div dir="rtl" className={styles.offcanvasMobileSearchArea}>
      <form action="#">
        <input type="search" disabled placeholder="תפריט" />
        <button  dir="ltr" type="submit">
          {/* <IoIosSearch /> */}
        </button>
      </form>
    </div>
  );
};

export default MobileSearch;
