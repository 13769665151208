import React, { useState } from "react";
import styles from "./ContactForm.module.scss";
import Button from "../../../components/UI/button";
import contactThumb from "../../../assets/images/about/contact.png";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Verify reCAPTCHA token
    if (!recaptchaToken) {
      alert("אנא אמת שאתה אדם על ידי אימות reCAPTCHA.");
      return;
    }

    // Create form data
    const formData = {
      name: name,
      email: email,
      message: message,
      recaptchaToken: recaptchaToken,
    };

    // Send form data to server
    axios
      .post("/contact", formData)
      .then((response) => {
        console.log(response);
        setSuccess(true);
        setMessage("")
        setEmail("")
        setName("")

      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="voopo__contact ptb--110">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className={styles.voopoContactForm}>
              <h2>אם אתה צריך ליצור איתנו קשר, אנא מלא את הטופס למטה.</h2>
              <form onSubmit={handleSubmit}>
                <div className={styles.singleContactForm}>
                  <input
                    type="text"
                    name="name"
                    placeholder="הקלידו את שמכם"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className={styles.singleContactForm}>
                  <input
                    type="email"
                    name="email"
                    placeholder="הקלידו את כתובת האימייל שלכם"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className={`${styles.singleContactForm} message`}>
                  <textarea
                    name="message"
                    placeholder="הקלידו את ההודעה"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <ReCAPTCHA
                sitekey="6Lfd1hMnAAAAAHbzb_8Z1KtYQqqTIHQ13BPQ6rIy"
                onChange={handleRecaptchaChange}
              />
                <div className={styles.contactBtn}>
               
                  <Button type="submit" text="שליחה" />
                </div>
              </form>
              {success && <p>ההודעה נשלחה בהצלחה!</p>}
           
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
            <div className={styles.contactThumb}>
              <img src={contactThumb} alt="voopo voip" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
