import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import ContactForm from "../containers/contact/contact-form";
import ContactMap from "../containers/contact/contact-map";
import  Button  from "../components/UI/button";

const Contact = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>הודעה מהירה | הצטרפות</title>
        <meta
          name="description"
          content="יצירת קשר"
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="הצטרפות" />
        {/* contact form */}
        <div className="voopo__contact ptb--110">
      <div className="container">
        <div className="row">
            <div >
            <div className="desktop-button-wrapper d-none d-lg-block" >
              <center>
      <p style={{color:"red" ,fontSize:"17px",textAlign:"center",alignContent:"center"}}>בחרו איפה לפתוח את התוכנה</p>

        <div style={{ display: 'flex', justifyContent:"center",alignItems: 'center' }}>
        <Button type="link" url="https://qmessage.co.il" text="במחשב" btnStyle="white" typee={true}  style={{display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    
                      alignItems: 'center',marginLeft:"11px" }}/>
      
        <Button type="link" url="https://play-g.qmessage.co.il" text="בנייד" btnStyle="white"  typee={true}   style={{display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',marginLeft:"11px" }}/>
                      </div>
                      </center> </div>  </div>  </div>
        </div>
    </div>
        {/* contact map */}
        {/* <ContactMap /> */}
      </LayoutOne>
    </Fragment>
  );
};

export default Contact;
