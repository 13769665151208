import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import ContactForm from "../containers/contact/contact-form";
import ContactMap from "../containers/contact/contact-map";

const Contact = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>הודעה מהירה | יצירת קשר</title>
        <meta
          name="description"
          content="יצירת קשר"
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="יצירת קשר" />
        {/* contact form */}
        <ContactForm />
        {/* contact map */}
        {/* <ContactMap /> */}
      </LayoutOne>
    </Fragment>
  );
};

export default Contact;
