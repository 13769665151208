import React from "react";
import { Link } from "react-router-dom";

const MobileNavMenu = ({ styles }) => {
  return (
    <nav className={styles.offcanvasNavigation} id="offcanvas-navigation">
      <ul dir="rtl">
        <li>
          <Link to={process.env.PUBLIC_URL + "/"}>דף הבית</Link>
          {/* <ul className={`${styles.subMenu}`}>
            <li>
              <Link to={process.env.PUBLIC_URL + "/home-one"}>
                Home Version One
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/home-two"}>
                Home Version Two
              </Link>
            </li>
          </ul> */}
        </li>

        <li 
        // className={styles.menuItemHasChildren}
        >
          <Link to={process.env.PUBLIC_URL + "/service"}>השירות</Link>
          {/* <ul className={`${styles.subMenu}`}>
            <li>
              <Link to={process.env.PUBLIC_URL + "/service"}>מה זה 'הודעה מהירה'?</Link>
            </li>
            <li>
              <Link
                to={process.env.PUBLIC_URL + "/service-who"}
              >
              
              למי זה יכול להתאים?
              </Link>
            </li>
            {/* <li>
              <Link to={process.env.PUBLIC_URL + "/service-details"}>
                Service Details Right Sidebar
              </Link>
            </li>
          </ul> */}
        </li>
        {/* <li>
          <Link to={process.env.PUBLIC_URL + "/about"}>אודותינו</Link>
        </li> */}
        <li
        //  className={styles.menuItemHasChildren}
         >
          <Link to={process.env.PUBLIC_URL + "/blog"}>עדכונים</Link>
         {/*  <ul className={`${styles.subMenu}`}>
            <li>
              <Link to={process.env.PUBLIC_URL + "/join-Wa"}>
              הצטרפות לקבוצת העדכונים בווצאפ
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog"}>
                Blog Right Sidebar
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-post-left-sidebar"}>
                Blog Post Left Sidebar
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-post"}>
                Blog Post Right Sidebar
              </Link>
            </li> 
          </ul>*/}
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>יצירת קשר</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
